@font-face {
  font-family: 'blacksans';
  src: url(./fonts/blacksans.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'blacksansbold';
  src: url(./fonts/blacksansbold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'blacksansblack';
  src: url(./fonts/blacksansblack.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'blacksanscon';
  src: url(./fonts/blacksanscon.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'clicker';
  src: url(./fonts/Clicker.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cookie';
  src: url(./fonts/Cookie.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}





/* .custom-cursor {
  cursor: url('./components/images/cursor-top.png'), auto;
} */

html {
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  background: white;
}


::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.555)
}


::-webkit-scrollbar-thumb:hover {
  background: black;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'blacksans';
  /* border: 1px solid red; */


}

body {
  margin: 0;
  padding: 0;
}

.navigation {
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: top 0.3s;



}

.navigation.hidden {
  top: -100px;
}

.logo {
  height: 7em;
  padding-left: 2em;
  padding-top: 0.5em;
}

.hamburger-container {

  position: absolute;
  top: 1em;
  right: 2em;
  z-index: 999;


}


.hamburger-wrapper {
  position: relative;
}

.custom-hamburger {
  /* color: #F875AA; */
  color: #fec8c8;

}

.custom-hamburger.open {
  position: absolute;
  right: 0.2em;
  transition: none;
  color: #fec8c8;
}

.nav-list {
  list-style-type: none;
  display: none;



}


.nav-list.open {
  display: block;
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 4em;
  width: 20em;
  background-color: white;



}

.nav-list.open li {

  padding-bottom: 20px;
}

.sayhello {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-family: 'blacksansblack';
  font-size: 1.4em;
}

.nav-list.open a {
  color: #000;
  text-decoration: none;
  font-size: 22px;

}





.section1 {
  position: relative;
  width: 100%;
  height: 100vh;
  /* Full height of the viewport */
  overflow: hidden;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  /* Keeps video at the lowest layer */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  /* Dark overlay above video */
}

.content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 3;
}

.title {
  font-size: 6rem;
  font-weight: bold;
  font-family: 'Clicker';
  animation: floatUp 1s ease-out forwards;
}

.subtitle {
  font-size: 1.5rem;
  animation: floatUp 1s ease-out 1s forwards;
}



.timer-overlay {
  position: absolute;
  bottom: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  z-index: 4;
  /* Timer above all other layers */
}






.timer-overlay {
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for perfect centering */
  color: white;
  /* Change text color so it is visible on top of the image */
  font-size: 2rem;
  /* Adjust font size as needed */
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  /* Optional: Soften the edges */

}

.timer-overlay h1 {
  color: black;
  font-size: 3em;
}

.section2 {

  padding-bottom: 3em;
  position: relative;
  /* Set relative positioning for the parent container */
  overflow: hidden;
}

.shape4 {
  position: absolute;
  /* Position absolutely */
  top: 27em;
  /* Adjust as needed */
  left: 1em;
  /* Adjust as needed */
  width: 100%;
  /* Adjust width to cover the container */
  height: auto;
  /* Maintain aspect ratio */
  z-index: 0;
  /* Send to back */
  transform: rotate(-25deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}

.shape8 {
  height: 9em;
  z-index: 0;
  top: 3%;
  left: 85%;
  position: absolute;
  transform: rotate(-25deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}

.shape1 {
  height: 12em;
  z-index: 0;
  top: 82%;
  left: 83%;
  position: absolute;
  transform: rotate(-25deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}


.shape2 {
  height: 10em;
  z-index: 0;
  top: 12%;
  left: 1%;
  position: absolute;
  transform: rotate(-35deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}

.shape4a {
  height: 10em;
  z-index: 0;
  top: 45%;
  left: 3%;
  position: absolute;
  transform: rotate(-2deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}

.shape6 {
  height: 10em;
  z-index: 0;
  top: 35%;
  left: 80%;
  position: absolute;
}

.shape7 {
  height: 2em;
}

.story {
  font-size: 4em;
  padding-top: 1em;
  padding-left: 1.5em;
  padding-bottom: 0.6em;
}

.story h3 {
  font-family: 'Clicker';
  padding-bottom: 0.1em;
}


.sec2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  position: relative;
  /* Ensure this is positioned above the background */
  z-index: 1;
}

.sec2a {
  display: flex;
  gap: 150px;
  /* Adjust spacing between text and image */
  position: relative;
  padding: 50px;
  /* Add padding as needed */
  background-color: #ad83302c;


}

.sec2b {
  display: flex;
  gap: 100px;
  /* Adjust spacing between text and image */
  position: relative;
  padding: 50px;
  /* Add padding as needed */
  background-color: rgba(0, 102, 255, 0.158);

}

.story-text {
  max-width: 400px;
}

.sec2a h4 {
  font-family: 'Clicker';
  padding-bottom: 7px;
  font-size: 3em;
}

.sec2b h4 {
  font-family: 'Clicker';
  padding-bottom: 7px;
  font-size: 3em;
}

.sec2a p {
  width: 30em;
}

.hug {
  width: 100%;
  /* Adjust image styling as needed */
  max-width: 180px;
}

.soap {
  width: 100%;
  /* Adjust image styling as needed */
  max-width: 250px;
}

.corner-lines {
  position: absolute;


}

.corner-lines::before,
.corner-lines::after {
  content: "";
  position: absolute;
  background-color: black;
}

/* Top-left corner */
.top-left {
  top: 10px;
  left: 10px;
}

.top-left::before {
  top: 0;
  left: 0;
  width: 80px;
  /* Adjust line length */
  height: 2px;
  /* Line thickness */
}

.top-left::after {
  top: 0;
  left: 0;
  width: 2px;
  /* Line thickness */
  height: 80px;
  /* Line length */
}

/* Bottom-right corner */
.bottom-right {
  bottom: 10px;
  right: 10px;
}

.bottom-right::before {
  bottom: 0;
  right: 0;
  width: 80px;
  /* Adjust line length */
  height: 2px;
  /* Line thickness */
}

.bottom-right::after {
  bottom: 0;
  right: 0;
  width: 2px;
  /* Line thickness */
  height: 80px;
  /* Line length */
}


.section3 {
  padding-bottom: 4em;
  padding-top: 4em;
  background-image: linear-gradient(rgba(6, 42, 95, 0.142), rgba(3, 0, 2, 0.9)), url('../src/images/DSCF4915.jpg');
  background-size: cover;
  /* Makes the image cover the whole background */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
}

.bridals {
  display: flex;
  justify-content: space-evenly;
}

.bride-text  {
  font-size: 4em;
  padding-left: 1.5em;


}

.bride-text h4 {
  font-family: 'Clicker';
  
}


.bridal-squad {
  text-align: center;
  border: 3px solid white;
  position: relative;
  height: 230px;
  width: 160px;
  border-top-left-radius: 20px;
}

.squad4 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid #fec8c8;
  border-bottom-right-radius: 20px;

}

.squad1 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid #fec8c8;
  border-bottom-right-radius: 20px;
}

.squad2 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid #fec8c8;
  border-bottom-right-radius: 20px;
}

.squad3 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid #fec8c8;
  border-bottom-right-radius: 20px;
}

.squad5 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid #fec8c8;
  border-bottom-right-radius: 20px;
}

.bridesmaids {
  padding-top: 3em;
}

.bridesmaids p {
  margin-top: 1.2em;
  text-align: center;
  color: white;
  line-height: 5px;
}

.describe {
  font-family: 'Cookie';
}

.groom {
  padding-top: 4em;
}

.grooms {
  display: flex;
  justify-content: space-evenly;
}

.squad6 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid rgba(0, 102, 255, 0.158);
  border-bottom-right-radius: 20px;

}

.squad7 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid rgba(0, 102, 255, 0.158);
  border-bottom-right-radius: 20px;

}

.squad8 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid rgba(0, 102, 255, 0.158);
  border-bottom-right-radius: 20px;

}

.squad9 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid rgba(0, 102, 255, 0.158);
  border-bottom-right-radius: 20px;

}

.squad10 {
  height: 220px;
  position: absolute;
  left: 7%;
  top: 8%;
  border: 3px solid rgba(0, 102, 255, 0.158);
  border-bottom-right-radius: 20px;

}



.gallery1 {
  font-size: 4em;
  padding-top: 0.6em;
  padding-left: 1.5em;
 

}

.gallery1 h3 {
  font-family: 'Clicker';
}

.section4 {
  padding-bottom: 2em;
  position: relative;
  /* Set relative positioning for the parent container */
  overflow: hidden;
  border-bottom: 1px solid grey;
}

.shape4aa {
  position: absolute;
  /* Position absolutely */
  top: 27em;
  /* Adjust as needed */
  left: 1em;
  /* Adjust as needed */
  width: 100%;
  /* Adjust width to cover the container */
  height: auto;
  /* Maintain aspect ratio */
  z-index: -1;
  /* Send to back */
  transform: rotate(-25deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}

.shape8a {
  height: 9em;
  z-index: -1;
  top: 3%;
  left: 17%;
  position: absolute;
  transform: rotate(-25deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}

.shape1a {
  height: 12em;
  z-index: -1;
  top: 82%;
  left: 83%;
  position: absolute;
  transform: rotate(-25deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
}


.shape2a {
  height: 10em;
  z-index: -1;
  top: 3%;
  left: 60%;
  position: absolute;
  transform: rotate(-35deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
  display: none;
}

.shape4b {
  height: 10em;
  z-index: -1;
  top: 45%;
  left: 3%;
  position: absolute;
  transform: rotate(-2deg);
  /* Rotate left (counterclockwise) */
  transform-origin: top left;
  display: none;
}

.shape6a {
  height: 10em;
  z-index: -1;
  top: 35%;
  left: 80%;
  position: absolute;
  display: none;
}

.shape7a {
  height: 2em;
  display: none;
}

.proposal {
  padding-bottom: 3.5em;

}

.proposal h4 {
  text-align: center;
  font-size: 2em;
  font-family: 'Clicker';
  padding-bottom: 0.5em;
}

.court h4 {
  text-align: center;
  font-size: 2em;
  font-family: 'Clicker';
  padding-bottom: 0.5em;

}

.prewed h4 {
  text-align: center;
  font-size: 2em;
  font-family: 'Clicker';
  padding-bottom: 0.5em;

}


.proposal-cont {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.prop img {

  height: 16em;
}

.prewed img {

  height: 20em;
}

.court img {

  height: 20em;
}
.trad img {

  height: 20em;
}

.prop {
  display: flex;
  justify-content: space-around;
}

.prewed {
  display: flex;
  justify-content: space-around;
}

.court {
  display: flex;
  justify-content: space-around;
}
.trad {
  display: flex;
  justify-content: space-around;
}


.trad p {
  text-align: center;
}

.white p {
  text-align: center;
}

.section5 {
  padding-bottom: 4em;
  padding-top: 4em;
  background-image: linear-gradient(rgba(6, 42, 95, 0.142), rgba(3, 0, 2, 0.9)), url('../src/images/DSCF5051.jpg');
  background-size: cover;
  /* Makes the image cover the whole background */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
}

.sec5 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;



}

.card1 {
  padding: 3em;
  background-color: rgba(255, 192, 203, 0.304);
  text-align: left;
  height: 20em;
  line-height: 25px;

}

.card1 h3 {
  font-size: 2em;
  font-family: 'clicker';
  padding-bottom: 0.5em;
  color: white;
}

.card2 h3 {
  font-size: 2em;
  font-family: 'clicker';
  padding-bottom: 0.5em;
  color: white;
}

.card1 p {
  width: 20em;
  color: white;

}

.card2 p {
  width: 20em;
  color: white;

}

.card1 a {
  font-family: 'blacksansbold';
  color: white;
}

.card2 a {
  font-family: 'blacksansbold';
  color: white;
}

.card2 {

  background-color: rgba(0, 102, 255, 0.158);
  padding: 3em;

  text-align: left;
  height: 20em;
  line-height: 25px;
}

.section6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border-top: 1px solid black;
}
.section6 img {
  height: 5em;
}

@media (max-width: 768px) {


  .logo {
    height: 4.5em;
    padding-left: 1em;
    padding-top: 1em
  }

  .video-bg {
    width: 100%;
    height: 100%;
    object-fit: contain
  }

  .section1 {
    width: 100%;
    height: 28vh;
  }

  .title {
    font-size: 2em;
    width: 10em
  }

  .subtitle {
    font-size: 1em
  }

  .content {
    top: 50%
  }

  .timer-overlay {
    top: 35%
  }


  .story {
    font-size: 3em
  }

  .sec2a {
    width: 20em;
  }

  .sec2b {
    width: 20em;
  }

  .story-text h4 {
    font-size: 1.3em;
  }

  .story-text p {
    width: 12em;
    font-size: 0.6em
  }

  .hug {
    height: 150px;
    width: 100px;

  }

  .soap {
    height: 100px;
    width: 120px;

  }

  .sec2a {
    gap: 10px;

  }

  .sec2b {
    gap: 10px
  }

  .shape6 {
    left: 50%;
    top: 70%;
  }

.bridals {
  flex-direction: column;
  align-items: center
}
.grooms {
  flex-direction: column;
  align-items: center
}

.bride-text  {
font-size: 3em;
text-align: center;
padding-right: 1.5em;


}

.bride-text h4 {
  text-align: center;
}


.gallery1 {
  font-size: 3em;
  text-align: center;
  padding-right: 1.5em;
  
  
  }
.gallery1 h3 {
  text-align: center
}

.prop {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 2em;
}
.court {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 2em;
}
.prewed {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 2em;
}
.trad {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 2em;
}

.sec5 {
flex-direction: column;
gap: 3em;
}

.card1 {
  height: 25em;
  width: 20em
}

.card1 p {
  width: 14em
}
.card2 {
  height: 16em;
  width: 20em
}

.card2 p {
  width: 14em
}

.name{
  padding-top: 1.7em;
}

.section6 p {
 font-size: 11px;
}

}


@media (max-width: 1024px) {
  .card1 {
    height: 25em;
    width: 20em
  } 

  .card1 p {
    width: 14em
  }
  .card2 {
    height: 25em;
    width: 20em
  } 

  .card2 p {
    width: 14em
  }

  .section3 img {
    height: 180px
  }

  .bridal-squad {
    height: 170px;
    width: 140px
  }
}

@import url('https://fonts.googleapis.com/css?family=Cairo');